.wrapper {
  padding-top: 40px;
}

.header {
  padding: 0 0 14px;
  border-bottom: 1px solid #f7f8fa;
}

.container {
  padding-left: 15px;
  //height: 100%;
  padding-right: 0;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}
.columnTitle {
  font-size: 18px;
  color: #001847;
  cursor: pointer;
  font-family: var(--font-poppins-medium);
  padding: 5px 5px 3px 10px;
  width: fit-content;
  border-radius: 6px;
  transition: all 200ms;
  margin-left: -10px;

  &:hover {
    background-color: #d8dfea;
  }
}

.columnTitle svg {
  font-size: 1em;
}

.collaborationLabel {
  margin-top: 2px;
  font-size: 13px;
  line-height: 1.23;
  color: var(--secondary-text-color);
  margin-top: 2px;
}

.filterButton {
  margin-left: 5px;
}

.divider {
  margin: 0 15px;
  background-color: #f7f8fa;
}

.callsColumn {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  height: calc(100vh - 105px - 68px);
  width: 285px;
}
.containerNoApp {
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.noAppDescription {
  font-size: 14px;
  color: #001847;
  text-align: center;
  padding: 20px 20px 0;
}
.downloadApp {
  display: flex;
  justify-content: center;
}
.downloadButton:hover {
  background-color: transparent;
}

.CallsItem:not(.CallsItemDragging) {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.CallsItem.CallsItemDragging {
  margin: 10px 0;
}

.CallsItemMenuOpen {
  background-color: #f2f2ff !important;
}

.CallsItemBody {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.missedCallName,
.missedCallPhoneNumber {
  color: var(--primary-text-error-color) !important;
}

.CallsItemAdditional {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.ContactDetails {
  margin-right: 10px;
  align-self: center;
}

.callDate {
  margin-top: 5px;
  color: var(--secondary-text-color);
  display: flex;
  align-items: center;
  font-size: 10px;
  white-space: nowrap;
}

.callDate.callDateMiddle {
  margin-bottom: 5px;
}

.callDate svg {
  margin-right: 5px;
  width: 4px;
  height: 4px;
}
.CallsItemCopy ~ [data-rbd-placeholder-context-id] {
  display: none !important;
}
.CallsItemCopy ~ div {
  transform: none !important;
}
.recentCallsMenuItem {
  position: relative;
  font-size: 13px;
  padding-left: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.recentCallsMenuItem > svg {
  color: #5f48e9;
  fill: #5f48e9;
  margin-right: 10px;
  width: 16px;
  height: 16px;
}
.CallsItemTags {
  display: flex;
}
.CallsItemTags > span {
  margin-left: 10px;
  font-size: 14px;
  color: #001847;
}
.callsActions > li > svg {
  margin-right: 10px;
  width: 13px;
  height: 13px;
}
.callsActions > li {
  font-size: 11px;
  color: #001847;
  padding-top: 8px;
  padding-bottom: 8px;
}
.activeFilterItem {
  //
}
.CallsItemNameNoSub {
  transform: translateY(50%);
}
.cardDivider {
  margin: 0 15px;
}

.filterStrip {
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  padding: 7px 16px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  color: var(--primary-text-color);
  background-color: #eff3f7;
}

.cancelFilterButton {
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-family: inherit;
  padding: 0;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.cancelFilterIcon {
  width: 100%;
  height: 100%;
  fill: var(--primary-text-color);
  transition: fill 0.25s;
}

.cancelFilterButton:hover .cancelFilterIcon {
  fill: var(--primary-button-color);
}

.assignToSection {
  margin-top: 5px;
  display: flex;
  align-items: flex-end;
}

.assignToSection .text {
  font-size: 13px;
  color: var(--secondary-text-color);
  margin-right: 5px;
}

.assignToSelect {
  vertical-align: bottom;
}

.CallsColumnFilter {
  width: 198px;
  border-radius: 6px;
  box-shadow: none !important;

  & > div {
    box-shadow: 10px 10px 40px 0 var(--color-mystic) !important;
  }
}

.CallsColumnFilterItem {
  position: relative;
}

.CallsColumnFilterItemCheck {
  display: flex;
  margin-left: auto;
  margin-right: 0 !important;
  margin-top: auto;
}

.CallsFilterDivider {
  height: 1px;
  background: var(--primary-border-color);
  width: 160px;
  margin: 8px auto;
}

.NoCallsText {
  font-size: 22px;
  color: var(--primary-text-color);
  font-family: var(--font-poppins-medium);
  text-align: center;
}
.nameEndAdornment {
  display: inline-flex;
  margin-left: 10px;
}
.nameEndAdornment span {
  padding: 2px 5px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 9px;
}
