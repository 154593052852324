.outerDroppable {
  position: relative;
  height: 100%;
  min-height: 1px;
}

.placeholder {
  z-index: 999;
  border-radius: 8px;
  transition: opacity 0.5s;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--primary-button-color);
  font-size: 18px;
}

.placeholder.visible {
  opacity: 1;
  visibility: visible;
  background-color: #fff;
  min-height: 100px;
}

.backgroundMask {
  position: relative;
  padding-left: 8px;
  padding-right: 8px;

  &.backgroundMaskEmpty {
    &:after {
      height: calc(100% + 63px) !important;
    }
  }

  & > div {
    position: relative;
    z-index: 2;
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: -55px;
    width: 100%;
    height: calc(100% + 77px);
    background-color: rgb(233, 237, 242);
    border-radius: 10px;
    z-index: 1;
  }
}
