.Icon {
  display: flex;

  &:hover {
    div {
      background-color: var(--primary-button-color);
    }
  }

  div {
    width: 5px;
    height: 5px;
    background-color: var(--primary-text-color);
    border-radius: 50%;

    &:not(&:first-child) {
      margin-left: 5px;
    }
  }
}
