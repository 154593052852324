.BoardCard {
  display: block;
}

.ContactCard {
  display: flex;
  overflow: hidden;
}

.Avatar {
  margin-right: 10px;
  flex-shrink: 0;
  align-self: flex-start;
}

.content {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.name {
  color: #2b2c31;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.number {
  color: var(--secondary-text-color);
}

.title {
  color: var(--primary-text-color);
  font-size: 11px;
  line-height: 1.45;
}

.bottom {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.totalTitle {
  color: var(--secondary-text-color);
  font-size: 13px;
}

.totalValue {
  color: var(--primary-text-color);
}

.mainContent {
  flex-grow: 1;
  margin-right: 10px;
  overflow: hidden;
}

.end {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.sendDate {
  color: var(--secondary-text-color);
  font-size: 10px;
  line-height: 1.6;
}

.isDraftLabel {
  height: 20px;
  padding: 0 10px;
  line-height: 20px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  color: #000;
  background-color: #f2f3f6;
}
