.DialogClear :global(.MuiDialog-paper) {
  max-width: 400px;
  border-radius: 20px;
}
.description {
  font-size: 13px;
  color: var(--primary-text-color);
  text-align: center;
}
.title {
  font-size: 17px;
  color: #5f48e9;
  margin-bottom: 15px;
  text-align: center;
}
